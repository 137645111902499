import React, { useState } from "react";
import { Accordion, Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { ReportViewCustomer, ReportViewOrder, ReportViewOrderItem } from "../model/report";

interface OrderListRendererProps {
    orders: ReportViewOrder[];
    filterCriteria: string;
    setFilterCriteria: (criteria: string) => void;
}

export default function OrderListRenderer(props: OrderListRendererProps) {

    const [enteredFilterCriteria, setEnteredFilteredCriteria] = useState<string>(props.filterCriteria);

    const search = () => {
        props.setFilterCriteria(enteredFilterCriteria);
    }

    const renderCustomerDetails = (customerDetails: ReportViewCustomer) => {
        return (<div>
            <p>{customerDetails.firstName} {customerDetails.lastName}</p>
            <p>{customerDetails.mobile}</p>
            <p>{customerDetails.email}</p>
            <p>{customerDetails.address}</p>
        </div>)
    }

    const renderOrderDetails = (orderItems: ReportViewOrderItem[]) => {
        return (<div>
            <Table size="sm">
                <tbody>
                    {orderItems.map(oi => (
                        <tr>
                            <td>{oi.productName}</td>
                            <td>{oi.quantity}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>);
    }

    return (<Accordion>
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Order List
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Search Text"
                                    value={enteredFilterCriteria}
                                    onChange={(event: any) => { setEnteredFilteredCriteria(event.target.value) }} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Button onClick={search}>Search</Button>
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Order Number</th>
                                <th>Customer Details</th>
                                <th>Order Details</th>
                                <th>Collection Slot</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.orders ? props.orders.map(o => (<tr>
                                <td>{o.id}</td>
                                <td>{renderCustomerDetails(o.customerDetails)}</td>
                                <td>{renderOrderDetails(o.items)}</td>
                                <td>{o.collectionSlot}</td>
                            </tr>)) : <tr><td colSpan={2}>No orders found</td></tr>}
                        </tbody>
                    </Table>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    </Accordion>)

}