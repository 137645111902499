import React from "react";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import { ReportState } from "../store/reducers/reportReducer";
import OrderListRenderer from "./orderListRenderer";
import ReportSummaryRenderer from "./reportSummaryRenderer";

interface ReportRendererProps {
    reportState: ReportState;
    changeCenter: (center: string) => void;
    searchOrder: (searchCriteria: string) => void;
}

export default function ReportRenderer(props: ReportRendererProps) {

    const onSelectedCenterChanged = (event: any) => {
        props.changeCenter(event.target.value);
    }

    return (<div>
        <Container>
            <Row>
                <Col sm={12} md={6}>
                    <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Select the center</Form.Label>
                            <Form.Control
                                as="select"
                                value={props.reportState.selectedCenter}
                                onChange={onSelectedCenterChanged}>
                                <option>-</option>    
                                {props.reportState.centers.map(c => (<option>{c}</option>))}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12}>
                    {props.reportState.selectedReport ? 
                    <Alert variant="info">
                        To View a Google Sheet containing all orders - Please click following URL <br/>
                        <a href={props.reportState.selectedReport.reportSheetUrl}>{props.reportState.selectedReport.reportSheetUrl}</a>
                    </Alert> : <React.Fragment />  }
                    
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12}>
                    <ReportSummaryRenderer
                        summary={props.reportState.selectedReport?.summary} />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12}>
                    <OrderListRenderer 
                            filterCriteria={props.reportState.filterCriteria} 
                            orders={props.reportState.filteredOrders}
                            setFilterCriteria={props.searchOrder} /> 
                </Col>
            </Row>
        </Container>
    </div>);
}