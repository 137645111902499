import React, { useEffect } from "react";
import SelectCenter from "./selectCenter";
import { RootState } from "../store/rootReducer";
import Item from "../model/item";
import { ThunkDispatch } from "redux-thunk";
import { setCenter } from "../store/actions/catalogActions";
import { connect } from "react-redux";
import { Alert, Button, Col, Row } from "react-bootstrap";
import ItemRenderer from "./itemRenderer";
import Cart from "../model/cart";
import { addToCart, removeFromCart } from "../store/actions/cartActions";
import { CartState } from "../store/reducers/cartReducer";
import { Link } from "react-router-dom";
import CenterMessage from "./centerMessage";
import { loadCollectionSlots } from "../store/actions/collectionSlotAction";
import {useParams, withRouter} from "react-router";

interface CatalogContainerStateProps {
    center: string;
    items: Item[];
    cartState: CartState;
}

interface CatalogContainerDispatchProps {
    setCenter: (center: string) => void;
    addToCart: (item: Item) => void;
    removeFromCart: (item: Item) => void;
    loadCollectionSlots: (center: string) => void;
}

type Props = CatalogContainerStateProps & CatalogContainerDispatchProps;

function CatalogContainer(props: Props) {

    console.log(props.cartState);

    const routeParams: any = useParams();
    const [showSelectCenter, setShowSelectCenter] = React.useState<boolean>(true);

    useEffect(() => {
        /*
        if(routeParams && routeParams.center)
        {
            setShowSelectCenter(false);
            props.setCenter(routeParams.center);
        }
        */
    }, []);

    const onSetSelectedCenter = (selectedCenter: string) => {
        setShowSelectCenter(false);
        props.setCenter(selectedCenter);
        props.loadCollectionSlots(selectedCenter);
    }

    const getItemQuantityInCart = (item: Item): number => {

        let quantity = 0;

        props.cartState.cart.items.forEach((value: number, key: Item) => {
            if(key.id === item.id)
                quantity = value;
        });

       return quantity;
    }

    const renderItems = () => {
        return props.items.map(r => (
           
                <ItemRenderer
                    item={r}
                    addToCart={props.addToCart}
                    quantity={getItemQuantityInCart(r)}
                    removeFromCart={props.removeFromCart} />
           
        ));
    }

    const renderCheckout = () => {

        if (props.cartState.cart.items.size > 0) {
            return (<Link
                to="/order/checkout"
                className="btn btn-primary btn-lg"
                style={{ width: "100%" }}
            >Checkout - ${props.cartState.cart.totalAmount}</Link>)
        }

        return <Button disabled={true} variant="primary" size="lg" style={{ width: "100%" }}>
            Checkout - ${props.cartState.cart.totalAmount}
        </Button>
    }

    const isNZCenter = () => props.center && props.center.startsWith("NZ-");

    return (<div>

        <div style={{paddingTop: "5px"}}>
            <SelectCenter show={showSelectCenter} setSelectedCenter={onSetSelectedCenter}></SelectCenter>
        </div>
        
        <Row>   
            <Col md={12} sm={12}>
                <Alert variant="info">
                    The center selected is - <strong> {props.center}</strong>
                </Alert>
                {isNZCenter() && (<>
                    <Alert variant="warning">
                        Payments will be accepted in Australian dollars. The exchange rate will apply for other currencies. 
                    </Alert>
                </>)}
            </Col>
        </Row>

        <Row> 
            <Col md={12} sm={12}>
            {renderItems()}
            </Col>
        </Row>

        <Row style={{ marginTop: "10px" }}>
            <Col md={12} sm={12}>
                {renderCheckout()}
            </Col>
        </Row>
    </div>);
}

const mapStateToProps = (state: RootState): CatalogContainerStateProps => {
    return {
        center: state.catalog.center,
        items: state.catalog.items,
        cartState: state.cart
    }
}

const mapDispatchToProps = (dispatch: any): CatalogContainerDispatchProps => {
    return {
        setCenter: (center: string) => dispatch(setCenter(center)),
        addToCart: (item: Item) => dispatch(addToCart(item)),
        removeFromCart: (item: Item) => dispatch(removeFromCart(item)),
        loadCollectionSlots: (center: string) => dispatch(loadCollectionSlots(center))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogContainer));
