import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

interface AddToCartButtonProps {
    quantity: number;
    addQuantity: () => void;
    subtractQuantity: () => void;
}

export default function AddToCartButton(props: AddToCartButtonProps) {

    const add = () => {
        props.addQuantity();
    }

    const subtract = () => {
        if(props.quantity > 0)
            props.subtractQuantity();
    }

    return (<div>  
        <ButtonGroup>
            <Button onClick={add}>+</Button>
            <Button>{props.quantity}</Button>
            <Button onClick={subtract}>-</Button>
        </ButtonGroup>
    </div>);
   
}