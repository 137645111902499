import { SetUserAuthAction, SET_USER_AUTH } from "../actions/userAuthAction"

export interface UserAuthState {
    token: string
}

const initialState: UserAuthState = {
    token: ""
}

const UserAuthReducer = (state: UserAuthState = initialState, action: SetUserAuthAction) => {
    
    const updatedState = {...state};

    switch(action.type) {
        case SET_USER_AUTH : {
            updatedState.token = action.token;
            break;
        }
        default: {
            return state;
        }
    }

    return updatedState;
}

export default UserAuthReducer;