import OrderCollectionSlot from "../../model/orderCollectionSlot";
import { CollectionSlotActions, LOAD_COLLECTION_SLOTS, LOAD_COLLECTION_SLOTS_SUCCESS } from "../actions/collectionSlotAction";

export interface CollectionSlotState {
    center: string;
    slots: OrderCollectionSlot[];
}

const initialState: CollectionSlotState = {
    center: "",
    slots: []
}

const CollectionSlotReducer = (
    state: CollectionSlotState = initialState,
    action: CollectionSlotActions): CollectionSlotState => {

    let copyOfState = { ...state };

    switch (action.type) {
        case LOAD_COLLECTION_SLOTS:
            {
                copyOfState.center = action.center;
                copyOfState.slots = [];
                break;
            }
        case LOAD_COLLECTION_SLOTS_SUCCESS:
            {
                copyOfState.center = action.center;
                copyOfState.slots =  action.collectionSlots;
                break;
            }
        default: {
            return state;
        }
    }

    return copyOfState;
}

export default CollectionSlotReducer;