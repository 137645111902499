import React from "react";
import { Alert, Carousel, Col, Container, Navbar, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";

function TopNav(routerProps: any) {

    const renderHeader = () => {
        return (
            <Container>
            <div>
                <Row>
                    <Col md={12} sm={12}>
                        <Carousel>
                            <Carousel.Item>
                                <img height="10%"
                                    className="d-block"
                                    style={{margin:"auto", display: "block"}}
                                    src="images/saree-2024.jpeg"
                                    alt="vicharan-t-shirt"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
                {/*<Row>
                    <Col md={12}>
                        <Alert variant="primary">
                            For size guide, please click following link &nbsp;
                        <a href="images/size-guide.jpg" target="_blank">Size Guide</a>
                        </Alert>
                    </Col>
                </Row>*/
                }   
            </div></Container>);
    }

    return (routerProps.location.pathname.indexOf("reports") === -1 ? renderHeader() : (<React.Fragment />));
}

export default withRouter(TopNav);