import React, { useEffect } from "react";
import { Customer } from "../model/cutomer";
import { AcquireCustomerDetailsState } from "../store/reducers/acquireCustomerDetailsReducer";
import { CartState } from "../store/reducers/cartReducer";
import { RootState } from "../store/rootReducer";
import AcquireCustomerDetails from "./acquireCustomerDetails";
import { setCustomerDetails } from "../store/actions/acquireCustomerDetailsAction";
import { connect } from "react-redux";
import CustomerDetails from "./customerDetails";
import CartRenderer from "./cartRenderer";
import PaypalButton from "./paypalButton";
import { CollectionSlotState } from "../store/reducers/collectionSlotReducer";

interface CheckoutContainerStateProps {
    cartState: CartState;
    customerState: AcquireCustomerDetailsState;
    center: string;
    collectionSlotState: CollectionSlotState;
}

interface CheckoutContainerDispatchProps {
    setCustomerDetails: (customer: Customer) => void;
}

type CheckoutContainerProps = CheckoutContainerStateProps & CheckoutContainerDispatchProps;

function CheckoutContainer(props: CheckoutContainerProps) {

    useEffect(() => {
        console.log("use-effect");
        console.log(props.collectionSlotState);
    }, []);    

    return (<div>
        {!props.customerState.customerDetailsEntered ?
            (<AcquireCustomerDetails center={props.center} 
                setCustomerDetails={props.setCustomerDetails}
                collectionSlots={props.collectionSlotState.slots} />) :
            (<div>
                <CustomerDetails customer={props.customerState.customer as Customer} />
                <CartRenderer cart={props.cartState.cart} />
                <PaypalButton
                    cart={props.cartState.cart}
                    customerDetails={props.customerState.customer as Customer}
                    center={props.center} />
            </div>)}
    </div>);
}

const mapStateToProps = (state: RootState): CheckoutContainerStateProps => {
    return {
        center: state.catalog.center,
        cartState: state.cart,
        customerState: state.customer,
        collectionSlotState: state.collectionSlot
    }
}

const mapDispatchToProps = (dispatch: any): CheckoutContainerDispatchProps => {
    return {
        setCustomerDetails: (customer: Customer) => dispatch(setCustomerDetails(customer))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer);