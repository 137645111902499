import React, { ReactElement } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import Cart from "../model/cart";
import Item from "../model/item";

interface CartRendererProps {
    cart: Cart
}

export default function CartRenderer(props: CartRendererProps) {

    const renderCartItems = () => {
        const items: ReactElement[] = [];

        props.cart.items.forEach((quantity: number, item: Item) => {
            items.push((<tr>
                <td>{item.name}</td>
                <td>${item.price}</td>
                <td>{quantity}</td>
                <td>${item.price * quantity}</td>
            </tr>))
        });

        return items;
    }

    return (<Container>
        <Table striped bordered hover responsive="sm">
            <thead>
                <tr>
                    <th>Item Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                {renderCartItems()}
                <tr>
                    <td></td>
                    <td></td>
                    <td>Total Amount</td>
                    <td>${props.cart.totalAmount}</td>
                </tr>
            </tbody>
        </Table>
    </Container>)
}