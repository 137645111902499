import Item from "../../model/item";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export interface AddToCartAction {
    type: typeof ADD_TO_CART,
    item: Item
}

export interface RemoveFromCartAction {
    type: typeof REMOVE_FROM_CART,
    item: Item
}

export type CartActions = AddToCartAction | RemoveFromCartAction;

const createAddToCartAction = (item: Item) : AddToCartAction => {
    return {
        type: ADD_TO_CART,
        item
    };
}

const createRemoveFromCartAction = (item: Item) : RemoveFromCartAction => {
    return {
        type: REMOVE_FROM_CART,
        item
    };
}

export const addToCart = (item: Item) => (dispatch: any) => {
    dispatch(createAddToCartAction(item));
}

export const removeFromCart = (item: Item) => (dispatch: any) => {
    dispatch(createRemoveFromCartAction(item));
}