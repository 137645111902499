
export const SET_USER_AUTH = "SET_USER_AUTH";

export interface SetUserAuthAction {
    type: typeof SET_USER_AUTH,
    token: string
}

export const setUserAuthToken = (token: string) => async (dispatch: any) => {
    dispatch({
        type: SET_USER_AUTH,
        token
    });
}