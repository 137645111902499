import React from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import Item from "../model/item";
import AddToCartButton from "./addToCartButton";

interface ItemRendererProps {
    item: Item;
    quantity: number;
    addToCart: (item: Item) => void;
    removeFromCart: (item: Item) => void;
}

export default function ItemRenderer(props: ItemRendererProps) {

    const addToCart = () => {
        if(!props.item.stockRemaining ||
            props.item.stockRemaining === 0 ||
            props.quantity >= props.item.stockRemaining)
            return;

        props.addToCart(props.item);
    }

    const removeFromCart = () => {
        props.removeFromCart(props.item);
    }

    const renderStock = () => {
        return props.item.stockRemaining == null ? (<React.Fragment />) :
            (
                <div className="btn btn-primary" style={{width: "100%"}}>
                    Stock <Badge variant="light">{props.item.stockRemaining}</Badge>
                </div>);
    }

    return (
       
            <Row>
                <Col md={3} sm={12}>
                    {props.item.name}
                </Col>
                <Col md={3} sm={12} style={{padding:"5px"}}>
                    <AddToCartButton
                        quantity={props.quantity}
                        subtractQuantity={removeFromCart}
                        addQuantity={addToCart} />
                </Col>
                <Col md={3} sm={12}>
                    <b><h3>${props.item.price}</h3></b>
                </Col>
                <Col md={3} sm={12} style={{padding:"5px"}}>
                    {renderStock()}
                </Col>
            </Row>
       )
}