import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./login";
import { RootState } from "../store/rootReducer";
import { UserAuthState } from "../store/reducers/userAuthReducer";
import { setUserAuthToken } from "../store/actions/userAuthAction";
import { connect } from "react-redux";
import { auth0ReportApiAudience } from "../AppSettings";
import { ReportState } from "../store/reducers/reportReducer";
import ReportRenderer from "./reportRenderer";
import { filterReport, loadReport, showReportForCenter } from "../store/actions/reportAction";
import { Col, Row, Button, Jumbotron } from "react-bootstrap";

interface ReportStateProps {
    userAuth: UserAuthState;
    reportState: ReportState;
}

interface ReportDispatchProps {
    setUserAuth: (token: string) => void;
    changeCenter: (center: string) => void;
    filterReport: (filterCriteria: string) => void;
    loadReport: (token: string) => void
}

type ReportProps = ReportStateProps & ReportDispatchProps;

export function Report(props: ReportProps) {

    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (isAuthenticated) {
            retrieveUserToken();
        }
    }, [isAuthenticated]);

    const retrieveUserToken = async () => {
        const token = await getAccessTokenSilently({
            audience: auth0ReportApiAudience
        });

        props.setUserAuth(token);
    }

    const renderReport = () => {

        if (!props.reportState.reportLoaded &&
            !props.reportState.reportLoadingInProgress) {
            return (<div>
                <Jumbotron>
                    <h1>Diwali Sweets Ordering System Reports</h1>
                    <p>
                        <Button
                            variant="primary"
                            size="lg"
                            onClick={() => props.loadReport(props.userAuth.token)}>Load Report</Button>
                    </p>
                </Jumbotron>
            </div>);
        }

        if (!props.reportState.reportLoaded &&
            props.reportState.reportLoadingInProgress) {
            return (<Jumbotron>
                <h1>Diwali Sweets Ordering System Reports</h1>
                <p>
                    Please wait while reports are being loaded...!
                </p>
            </Jumbotron>)
        }

        return (<ReportRenderer
            reportState={props.reportState}
            changeCenter={props.changeCenter}
            searchOrder={props.filterReport} />);
    }

    return (isAuthenticated ? renderReport() : (<Login />));
}

const mapStateToProps = (state: RootState): ReportStateProps => {
    return {
        userAuth: state.userAuth,
        reportState: state.report
    }
}

const mapDispatchToProps = (dispatch: any): ReportDispatchProps => {
    return {
        setUserAuth: (token: string) => dispatch(setUserAuthToken(token)),
        changeCenter: (center: string) => dispatch(showReportForCenter(center)),
        filterReport: (searchCriteria: string) => dispatch(filterReport(searchCriteria)),
        loadReport: (token: string) => dispatch(loadReport(token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
