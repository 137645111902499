import Axios from "axios";
import Item from "../../model/item";
import { apiBaseUrl } from "../../AppSettings";
import OrderCollectionSlot from "../../model/orderCollectionSlot";
import { createLoadCollectionSlotsSuccessAction } from "./collectionSlotAction";

export const SET_CENTER = "SET_CENTER";
export const LOAD_CATALOG_START = "LOAD_CATALOG_START";
export const LOAD_CATALOG_SUCCESS = "LOAD_CATALOG_SUCCESS";
export const LOAD_CATALOG_ERROR = "LOAD_CATALOG_ERROR";

export interface LoadCatalogStartAction {
    type: typeof LOAD_CATALOG_START,
    center: string
}

export interface LoadCatalogSuccessAction {
    type: typeof LOAD_CATALOG_SUCCESS,
    items: Item[]
}

export interface LoadCatalogErrorAction {
    type: typeof LOAD_CATALOG_ERROR,
    error: Error
}

export interface SetCenter {
    type: typeof SET_CENTER,
    center: string
}

export type CatalogActionTypes = SetCenter | LoadCatalogStartAction | LoadCatalogSuccessAction | LoadCatalogErrorAction;

const createSetCenter = (center: string): SetCenter => {
    return {
        type: SET_CENTER,
        center
    }
}

const createLoadCatalogStartAction = (center: string): LoadCatalogStartAction => {
    return {
        type: LOAD_CATALOG_START,
        center
    }
}

const createLoadCatalogSuccessAction = (items: Item[]): LoadCatalogSuccessAction => {
    return {
        type: LOAD_CATALOG_SUCCESS,
        items
    }
}

export const setCenter = (center: string) => async (dispatch: any) => {
    dispatch(createSetCenter(center));

    dispatch(createLoadCatalogStartAction(center));

    const response = await Axios.get<Item[]>(`${apiBaseUrl}/api/products/center/${center}`);
    const items = response.data;

    dispatch(createLoadCatalogSuccessAction(items));
}