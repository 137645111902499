import React from "react";
import { Switch, Route } from "react-router-dom";
import Report from "./admin/report";
import CatalogContainer from "./catalog/catalogContainer";
import CheckoutContainer from "./checkout/checkoutContainer";

export default function Routes() {
    return (<Switch>
        <Route path="/:center?" exact component={CatalogContainer} />
        <Route path="/order/checkout" exact component={CheckoutContainer} />
        <Route path="/admin/reports" exact component={Report} />
    </Switch>)
}