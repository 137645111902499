//@ts-nocheck
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import Axios from "axios";
import { apiBaseUrl, paypalAppClientIdDev, paypalAppClientIdProd } from "../AppSettings";
import { Jumbotron, Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cart from "../model/cart";
import Item from "../model/item";
import Customer from "../model/cutomer";

const CLIENT_ID =
    process.env.NODE_ENV === "production" ? paypalAppClientIdProd : paypalAppClientIdDev;

interface Props {
    isScriptLoaded: boolean;
    isScriptLoadSucceed: boolean;
    customerDetails: Customer;
    cart: Cart;
    center: string;
}

function PaypalButton(props: Props) {

    const [paymentSuccessful, setPaymentSuccessful] = React.useState<boolean | null>(null);
    const [paymentError, setPaymentError] = React.useState<boolean | null>(null);
    const [orderId, setOrderId] = React.useState<string>(null);
    const [paymentInProgress, setPaymentInProgress] = React.useState<boolean>(false);

    useEffect(() => {
        window.React = React;
        window.ReactDOM = ReactDOM;
    }, []);

    const onCreateOrder = async () => {

        const cartItems: [] = [];

        props.cart.items.forEach((quantity: number, item: Item) => {
            cartItems.push({ quantity, itemId: item.id });
        });

        const request = {
           orderItemRequests: cartItems,
           customerDetails: {
               firstName: props.customerDetails.firstName,
               lastName: props.customerDetails.lastName,
               mobile: props.customerDetails.mobile,
               email: props.customerDetails.email,
               center: props.center,
               address: props.customerDetails.address
           },
           collectionSlotId: props.customerDetails.slotId ?
                             parseInt(props.customerDetails.slotId) :
                             null   
        };

        setPaymentInProgress(true);

        try {
            const response = await Axios.post<CreateOrderIdResponse>(`${apiBaseUrl}/api/orders`, request);
            setOrderId(response.data.id);
            return response.data.id;
        }
        catch (ex) {
            setPaymentInProgress(false);
            setPaymentError(true);
        }
    }

    const onApproveOrder = async (data) => {

        setPaymentInProgress(true);

        try {
            await Axios.post(`${apiBaseUrl}/api/CaptureOrder`, data);
            setPaymentSuccessful(true);
        } catch (ex) {
            setPaymentInProgress(false);
            setPaymentError(true);
        }
    }

    const renderPayButton = () => {

        const PaypalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

        return (
            <div>
                {paymentInProgress ? (renderPaymentInProgress()) : (<React.Fragment />)}
                <PaypalButton
                    createOrder={onCreateOrder}
                    onApprove={onApproveOrder}>
                </PaypalButton>
            </div>)
    }

    const renderLoading = () => {
        return (<div>Loading Paypal button</div>);
    }

    const renderPaymentSuccessful = () => {
        return (<div>
            <Jumbotron>
                <h1>Jai Swaminarayan {props.customerDetails.firstName},</h1>
                <p>
                    Thank you for the payment. Your will receive an confirmation email shortly. You will receive the receipt of your payment from Paypal shortly.
                </p>
                <p>
                    <a href={`/`} className="btn btn-primary btn-lg">Return</a>
                </p>
            </Jumbotron>
        </div>)
    }

    const renderPaymentError = () => {
        return (<div>
            <Jumbotron>
                <Alert key="paymentError" variant="danger">
                    <h1>Something went wrong with the transaction - Please note following number {orderId}</h1>
                </Alert>
                <p>
                    <Link to={`/`} className="btn btn-danger">Return</Link>
                </p>
            </Jumbotron>
        </div>)
    }

    const renderPaymentInProgress = () => {
        return (<div>
            <Alert key="paymentInProgress" variant="primary">
                <h3>Please wait while payment is being processed. Do not press Paypal button again. <Spinner animation="grow" /></h3>
            </Alert>
        </div>)
    }

    const renderUi = () => {
        if (paymentSuccessful)
            return renderPaymentSuccessful();

        if (paymentError)
            return renderPaymentError();

        if (props.isScriptLoadSucceed && props.isScriptLoadSucceed)
            return renderPayButton();

        return renderLoading();
    }

    return (<div>
        {renderUi()}
    </div>)
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=AUD`)(PaypalButton);