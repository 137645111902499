import { Report, ReportViewOrder } from "../../model/report";
import { SEARCH_ORDERS, LOAD_REPORT_START, LOAD_REPORT_SUCCESS, reportActionTypes, SHOW_REPORT_FOR_CENTER } from "../actions/reportAction";

export interface ReportState {
    selectedReport: Report | undefined;
    availableReports: Report[];
    centers: string[];
    selectedCenter: string;
    filterCriteria: string;
    filteredOrders: ReportViewOrder[],
    reportLoaded: boolean,
    reportLoadingInProgress: boolean;
}

const initialState: ReportState = {
    selectedReport: undefined,
    availableReports: [],
    centers: [],
    selectedCenter: "",
    filterCriteria: "",
    filteredOrders: [],
    reportLoaded: false,
    reportLoadingInProgress: false
}

const ReportReducer = (state: ReportState = initialState, action: reportActionTypes): ReportState => {

    const copyOfState = { ...state };

    switch (action.type) {
        case LOAD_REPORT_START: {
            copyOfState.selectedReport = undefined;
            copyOfState.availableReports = [];
            copyOfState.centers = [];
            copyOfState.selectedCenter = "";
            copyOfState.filterCriteria = "";
            copyOfState.reportLoaded = false;
            copyOfState.reportLoadingInProgress = true;
            break;
        }

        case LOAD_REPORT_SUCCESS: {
            copyOfState.availableReports = action.reports;
            copyOfState.reportLoaded = true;
            copyOfState.centers = action.reports.map(r => r.center);
            copyOfState.reportLoadingInProgress = false;
            break;
        }

        case SHOW_REPORT_FOR_CENTER: {
            copyOfState.selectedCenter = action.center;
            copyOfState.selectedReport = copyOfState.availableReports.find(r => r.center === action.center);
            copyOfState.filteredOrders = copyOfState.selectedReport ? copyOfState.selectedReport.orders : [];
            break;
        }

        case SEARCH_ORDERS: {
            copyOfState.filterCriteria = action.filterCriteria;
            copyOfState.filteredOrders = copyOfState.selectedReport ? copyOfState.selectedReport.orders : [];
            break;
        }

        default: {
            return state;
        }
    }

    if (copyOfState.selectedCenter && copyOfState.availableReports)
        copyOfState.selectedReport = copyOfState.availableReports.find(r => r.center === copyOfState.selectedCenter);

    if (copyOfState.filterCriteria && copyOfState.selectedReport)
        copyOfState.filteredOrders = filterOrders(copyOfState.filterCriteria.trim().toLowerCase(), copyOfState.selectedReport.orders);

    return copyOfState;
}


const filterOrders = (searchCriteria: string, allOrders: ReportViewOrder[]): ReportViewOrder[] => {

    const result = [];

    for (let index = 0; index < allOrders.length; index++) {
        const eachOrder = allOrders[index];
        if (eachOrder.customerDetails.firstName.toLowerCase().indexOf(searchCriteria) !== -1 ||
            eachOrder.customerDetails.lastName.toLowerCase().indexOf(searchCriteria) !== -1 ||
            eachOrder.customerDetails.mobile.toLowerCase().indexOf(searchCriteria) !== -1 ||
            eachOrder.id.toLowerCase().indexOf(searchCriteria) !== -1 ||
            (eachOrder.customerDetails.address !== null && eachOrder.customerDetails.address.toLowerCase().indexOf(searchCriteria) !== -1) ||
            eachOrder.items.findIndex(oi => oi.productName.toLowerCase().indexOf(searchCriteria) !== -1) !== -1) {
            result.push(eachOrder);
        }

    }

    return result;
}

export default ReportReducer;