import { Customer } from "../../model/cutomer";
import { AddCustomerDetailsActions, ADD_CUSTOMER_DETAILS } from "../actions/acquireCustomerDetailsAction";

export interface AcquireCustomerDetailsState {
    customer: Customer | null,
    customerDetailsEntered: boolean
}

const initialState: AcquireCustomerDetailsState = {
    customer: null,
    customerDetailsEntered: false
}

const AcquireCustomerDetailsReducer = (state = initialState, action: AddCustomerDetailsActions) => {

    const updatedState = { ...state };

    switch (action.type) {
        case ADD_CUSTOMER_DETAILS: {
            updatedState.customerDetailsEntered = true;
            updatedState.customer = { ...action.customer };
            break;
        }
        default: {
            return state;
        }
    }

    return updatedState;

}

export default AcquireCustomerDetailsReducer;