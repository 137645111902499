import { Customer } from "../../model/cutomer";

export const ADD_CUSTOMER_DETAILS = "ADD_CUSTOMER_DETAILS";

export interface AddCustomerDetails {
    type: typeof ADD_CUSTOMER_DETAILS,
    customer: Customer
}

export type AddCustomerDetailsActions = AddCustomerDetails;

const createAddCustomerDetailsAction = (details: Customer) => {
    return {
        type: ADD_CUSTOMER_DETAILS,
        customer: details
    }
}

export const setCustomerDetails = (customerDetails: Customer) => (dispatch: any) => {
    dispatch(createAddCustomerDetailsAction(customerDetails));
}