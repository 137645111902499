import Axios, { AxiosRequestConfig } from "axios";
import { apiBaseUrl } from "../../AppSettings";
import { Report } from "../../model/report";

export const LOAD_REPORT_START = "LOAD_REPORT_START";
export const LOAD_REPORT_SUCCESS = "LOAD_REPORT_SUCCESS";
export const SHOW_REPORT_FOR_CENTER = "SHOW_REPORT_FOR_CENTER";
export const SEARCH_ORDERS = "SEARCH_ORDERS";
export const DISABLE_PRODUCT = "DISABLE_PRODUCT";
export const ENABLE_PRODUCT = "ENABLE_PRODUCT";

export interface LoadReportAction {
    type: typeof LOAD_REPORT_START;
}

export interface LoadReportSuccess {
    type: typeof LOAD_REPORT_SUCCESS;
    reports: Report[]
}

export interface ShowReportForCenter {
    type: typeof SHOW_REPORT_FOR_CENTER,
    center: string
}

export interface SearchOrders {
    type: typeof SEARCH_ORDERS,
    filterCriteria: string
}

export interface DisableProduct {
    type: typeof DISABLE_PRODUCT;
    productId: number
}

export interface EnableProduct {
    type: typeof ENABLE_PRODUCT;
    productId: number
}

export type reportActionTypes = LoadReportAction | 
                                LoadReportSuccess | 
                                ShowReportForCenter | 
                                SearchOrders |
                                DisableProduct |
                                EnableProduct;

const createLoadReportStartAction = () : LoadReportAction => {
    return {
        type: LOAD_REPORT_START
    };
}

const createLoadReportSuccessAction = (reports: Report[]) : LoadReportSuccess => {
    return {
        type: LOAD_REPORT_SUCCESS,
        reports
    }
}

const createShowReportForCenterAction = (center: string) : ShowReportForCenter => {
    return {
        type: SHOW_REPORT_FOR_CENTER,
        center
    }
}

const createSearchOrdersAction = (filterCriteria: string) : SearchOrders => {
    return {
        type: SEARCH_ORDERS,
        filterCriteria
    }
}

const createDisableProductAction = (productId: number) : DisableProduct => {
    return {
        type: DISABLE_PRODUCT,
        productId
    }
}

const createEnableProductAction = (productId: number) : EnableProduct => {
    return {
        type: ENABLE_PRODUCT,
        productId
    }
}

export const  loadReport = (token: string) => async (dispatch: any) => {
    dispatch(createLoadReportStartAction())

    const requestConfig: AxiosRequestConfig = {};
    requestConfig.headers = {'Authorization': `Bearer ${token}` };

    const response = await Axios.get<Report[]>(`${apiBaseUrl}/api/reports`, requestConfig);

    dispatch(createLoadReportSuccessAction(response.data));
}

export const showReportForCenter = (center: string) => (dispatch: any) => {
    dispatch(createShowReportForCenterAction(center));
}

export const filterReport = (searchCriteria: string) => (dispatch: any) => {
    dispatch(createSearchOrdersAction(searchCriteria));
}

export const disableProduct = (productId: number) => (dispatch: any) => {
    dispatch(createDisableProductAction(productId));
    
}