import Axios from "axios";
import { apiBaseUrl } from "../../AppSettings";
import OrderCollectionSlot from "../../model/orderCollectionSlot";

export const LOAD_COLLECTION_SLOTS = "LOAD_COLLECTION_SLOTS";
export const LOAD_COLLECTION_SLOTS_SUCCESS = "LOAD_COLLECTION_SLOTS_SUCCESS";

export interface LoadCollectionSlots {
    type: typeof LOAD_COLLECTION_SLOTS;
    center: string;
}

export interface LoadCollectionSlotsSuccess {
    type: typeof LOAD_COLLECTION_SLOTS_SUCCESS;
    center: string;
    collectionSlots: Array<OrderCollectionSlot>;
}

export type CollectionSlotActions = LoadCollectionSlots | LoadCollectionSlotsSuccess;

export const createLoadCollectionSlotAction = (center: string): LoadCollectionSlots => {
    return {
        type: LOAD_COLLECTION_SLOTS,
        center
    };
}

export const createLoadCollectionSlotsSuccessAction = (
    center: string, 
    slots: Array<OrderCollectionSlot>) : LoadCollectionSlotsSuccess => {
        return {
            type: LOAD_COLLECTION_SLOTS_SUCCESS,
            center,
            collectionSlots: slots
        }
    }

export const loadCollectionSlots = (center: string) => async (dispatch: any) => {
    dispatch(createLoadCollectionSlotAction(center.toLowerCase()));

    const response = await Axios.get<Array<OrderCollectionSlot>>(`${apiBaseUrl}/api/CollectionSlots/center/${center}`);

    dispatch(createLoadCollectionSlotsSuccessAction(center, response.data));
}