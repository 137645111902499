import Item from "../../model/item";
import { CatalogActionTypes, LOAD_CATALOG_START, LOAD_CATALOG_SUCCESS, SET_CENTER } from "../actions/catalogActions";

export interface CatalogContainerState {
    center: string;
    items: Item[];
}

const initialState: CatalogContainerState = {
    center: "",
    items: []
};

const CatalogReducer = (state: CatalogContainerState = initialState, action: CatalogActionTypes): CatalogContainerState => {

    let updatedState = { ...state };
    console.log(action.type);
    switch (action.type) {
        case SET_CENTER: {
            updatedState.center = action.center;
            break;
        }
        case LOAD_CATALOG_START: {
            updatedState.items = [];
            break;
        }
        case LOAD_CATALOG_SUCCESS: {
            updatedState.items = action.items;
            break;
        }
        default: {
            return state;
        }
    }

    return updatedState;
}

export default CatalogReducer;