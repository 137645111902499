import { combineReducers } from "redux";
import AcquireCustomerDetailsReducer, { AcquireCustomerDetailsState } from "./reducers/acquireCustomerDetailsReducer";
import CartReducer, { CartState } from "./reducers/cartReducer";
import CatalogReducer, { CatalogContainerState } from "./reducers/catalogReducer";
import CollectionSlotReducer, { CollectionSlotState } from "./reducers/collectionSlotReducer";
import ReportReducer, { ReportState } from "./reducers/reportReducer";
import UserAuthReducer, { UserAuthState } from "./reducers/userAuthReducer";

export interface RootState {
    catalog: CatalogContainerState;
    cart: CartState;
    customer: AcquireCustomerDetailsState;
    userAuth: UserAuthState;
    report: ReportState;
    collectionSlot: CollectionSlotState;
}

const rootReducer = combineReducers<RootState>({
    catalog: CatalogReducer,
    cart: CartReducer,
    customer: AcquireCustomerDetailsReducer,
    userAuth: UserAuthReducer,
    report: ReportReducer,
    collectionSlot: CollectionSlotReducer
});

export default rootReducer;