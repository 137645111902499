import React from "react";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Customer } from "../model/cutomer";
import OrderCollectionSlot from "../model/orderCollectionSlot";

interface CustomerDetailsFormProps {
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    address: string;
    center: string;
    slotId: number | null;
    slotCount: number;
}

interface AcquireCustomerDetailsProps {
    setCustomerDetails: (customer: Customer) => void;
    center: string;
    collectionSlots: OrderCollectionSlot[];
}

export default function AcquireCustomerDetails(props: AcquireCustomerDetailsProps) {

    const defaultFormValues: CustomerDetailsFormProps = {
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
        address: "",
        center: props.center,
        slotId: null,
        slotCount: props.collectionSlots ? props.collectionSlots.length : 0
    }

    const customerDetailsSchema = Yup.object().shape({
        firstName: Yup.string().required().max(50),
        lastName: Yup.string().required().max(50),
        mobileNumber: Yup.string().matches(/0[0-9]{9,10}\b/gm, "Please enter mobile number in 04xxxxxxxx (AU)/ 02XXXXXXXXX (NZ)").required(),
        email: Yup.string().email().required(),
        address: Yup.string().when("center", {
            is: "Melbourne",
            then: Yup.string().required()
        }),
        slotCount: Yup.number(),
        slotId: Yup
            .number()
            .nullable(true)
            .test('requiredIfSlotPresent',
                'Please select one of the given slot',
                (value: any) => {
                    if(!props.collectionSlots ||
                        props.collectionSlots.length === 0)
                        return true;
                    
                    return value && value > 0; 
                }
            )
    });

    const onNextClick = (values: CustomerDetailsFormProps) => {
        props.setCustomerDetails({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            mobile: values.mobileNumber,
            address: values.address,
            slotId: values.slotId as number,
        });
    }

    const renderSlots = (formikProps: FormikProps<CustomerDetailsFormProps>) => {
        return props.collectionSlots.map(cs => ((<Form.Check
            type="radio"
            label={cs.slot}
            value={cs.id}
            name="slotId"
            onChange={formikProps.handleChange} />)));
    }

    const renderForm = (formikProps: FormikProps<CustomerDetailsFormProps>) => {

        //@ts-ignore
        return (<Form onSubmit={formikProps.handleSubmit}>

            <input type="hidden" name="slotCount" value={formikProps.values.slotCount} />

            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text"
                            placeholder="Enter first name"
                            onChange={formikProps.handleChange}
                            value={formikProps.values.firstName}
                            name="firstName" />
                        <Form.Text className="text-muted">
                            Please enter your first name.
                        </Form.Text>
                        {formikProps.touched &&
                            formikProps.touched.firstName &&
                            formikProps.errors.firstName ?
                            (<div className="fade alert alert-danger show">{formikProps.errors.firstName}</div>) : (<React.Fragment />)}
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text"
                            placeholder="Enter last name"
                            name="lastName"
                            onChange={formikProps.handleChange}
                            value={formikProps.values.lastName} />
                        <Form.Text className="text-muted">
                            Please enter your last name.
                </Form.Text>
                        {formikProps.touched &&
                            formikProps.touched.lastName &&
                            formikProps.errors.lastName ?
                            (<div className="fade alert alert-danger show">{formikProps.errors.lastName}</div>) : (<React.Fragment />)}
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email"
                            placeholder="Enter email"
                            name="email"
                            value={formikProps.values.email}
                            onChange={formikProps.handleChange} />
                        <Form.Text className="text-muted">
                            Please enter your email id. Receipt will be sent to the email id entered here.
                        </Form.Text>
                        {formikProps.touched &&
                            formikProps.touched.email &&
                            formikProps.errors.email ?
                            (<div className="fade alert alert-danger show">{formikProps.errors.email}</div>) : (<React.Fragment />)}
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formMobile">
                        <Form.Label>Mobile number</Form.Label>
                        <Form.Control type="text"
                            placeholder="04XXXXXXXX/02XXXXXXXXX"
                            name="mobileNumber"
                            onChange={formikProps.handleChange} />
                        <Form.Text className="text-muted">
                            Please enter your mobile number.
                        </Form.Text>
                        {formikProps.touched &&
                            formikProps.touched.mobileNumber &&
                            formikProps.errors.mobileNumber ?
                            (<div className="fade alert alert-danger show">{formikProps.errors.mobileNumber}</div>) : (<React.Fragment />)}
                    </Form.Group>
                </Col>
                {props.center !== "Melbourne" ? <React.Fragment /> : (
                    <Col md={12} sm={12}>
                        <Form.Group controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text"
                                name="address"
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your complete address.
                        </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.address &&
                                formikProps.errors.address ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.address}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>)}
            </Row>

            <Row>
                {(!props.collectionSlots || props.collectionSlots.length <= 0) ? <React.Fragment /> : (
                    <Col md={12} sm={12}>
                        <Form.Group controlId="formSlotSelector">
                            <Form.Label>Select the slot for collecting the order</Form.Label>

                            {renderSlots(formikProps)}

                            {formikProps.touched &&
                                formikProps.touched.slotId &&
                                formikProps.errors.slotId ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.slotId}</div>) : (<React.Fragment />)}

                        </Form.Group>
                    </Col>
                )}
            </Row>

            <Button variant="primary" type="submit" size="lg">
                NEXT
            </Button>
        </Form>);
    }

    return (
        <div>
            <Formik
                initialValues={defaultFormValues}
                onSubmit={onNextClick}
                render={renderForm}
                validationSchema={customerDetailsSchema}
            />
        </div>
    );
}