import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Customer } from "../model/cutomer";

interface CustomerDetailsProps {
    customer: Customer
}

export default function CustomerDetails(props: CustomerDetailsProps) {
    return (<Container>
        <Row>
            <Col md={6} sm={12}>
                <Form.Group controlId="formFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text"
                        readOnly={true}
                        value={props.customer.firstName}
                    />
                </Form.Group>
            </Col>
            <Col md={6} sm={12}>
                <Form.Group controlId="formLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text"
                        readOnly={true}
                        value={props.customer.lastName}
                    />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
                <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text"
                        readOnly={true}
                        value={props.customer.email}
                    />
                </Form.Group>
            </Col>
            <Col md={6} sm={12}>
                <Form.Group controlId="formMobileNumber">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control type="text"
                        readOnly={true}
                        value={props.customer.mobile}
                    />
                </Form.Group>
            </Col>

            <Col md={12} sm={12}>
                {props.customer.address ? (<Form.Group controlId="formAddress">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text"
                        readOnly={true}
                        value={props.customer.address}
                    />
                </Form.Group>) : (<React.Fragment />)}

            </Col>
        </Row>
    </Container>)
}