import React from "react";
import { Accordion, Card, Table, Button } from "react-bootstrap";
import { ReportSummary } from "../model/report";

export interface ReportSummaryRendererProps {
    summary: ReportSummary[] | undefined
}

export default function ReportSummaryRenderer(props: ReportSummaryRendererProps) {

    return (
        <Accordion>
            <Card>
                <Card.Header>
                    <Accordion.Toggle  as={Button} variant="link" eventKey="0">
                       Summary Report
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Ordered Count</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.summary ? props.summary.map(s => (<tr>
                                    <td>{s.productName}</td>
                                    <td>{s.orderedCount}</td>
                                    <td>
                                        {s.isActive ?
                                        (<Button variant="danger">Disable Ordering</Button>) :
                                        (<Button variant="success">Enable Ordering</Button>)}
                                    </td>
                                </tr>)) : <tr><td colSpan={2}>No orders found</td></tr>}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}