import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Container } from "react-bootstrap";

export default function Login() {

    const { loginWithRedirect } = useAuth0();

    return (<Container>
        <div style={{ margin: "0 auto", textAlign: "center" }}>
            <Button variant="primary" size="lg" active onClick={() => loginWithRedirect()}>
                Login To View Reports
        </Button>
        </div>
    </Container>);
}