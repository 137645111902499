import React from 'react';
import logo from './logo.svg';
import './App.css';
import TopNav from './TopNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Alert } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { Auth0Provider } from '@auth0/auth0-react';
import FooterImage from "./footer";

function App() {

  return (
    <Auth0Provider
      domain="sampark.au.auth0.com"
      clientId="czfa132MKz0i9Ktc1MzAflqiUrRNK2fb"
      redirectUri={`${window.location.origin}/reports`}
      audience="https://merchandise-api"
    >
      <BrowserRouter>
        <Container fluid="md">
          <Row>
            <Col>
              <div className="App">
                <header className="App-header">
                  <TopNav />
                </header>
                <div>
                  
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <Routes />
              </div>
            </Col>
          </Row>
          <Row>
            <div style={{ marginTop: 20 }}>
            { /*<FooterImage />*/ }
            </div>
          </Row>
        </Container>
      </BrowserRouter>
    </Auth0Provider>
  );
}

export default App;
