import Cart from "../../model/cart";
import Item from "../../model/item";
import { ADD_TO_CART, CartActions, REMOVE_FROM_CART } from "../actions/cartActions";

export interface CartState {
    cart: Cart
}

const initialState: CartState = {
    cart: {
        items: new Map<Item, number>(),
        totalAmount: 0
    }
}

const CartReducer = (state = initialState, action: CartActions): CartState => {

    const updatedCart = { ...state };

    switch (action.type) {
        case ADD_TO_CART: {
            addToCart(updatedCart.cart, action.item);
            break;
        }
        case REMOVE_FROM_CART: {
            removeFromCart(updatedCart.cart, action.item)
            break;
        }
        default: {
            return state;
        }
    }

    calculateTotalCartAmount(updatedCart.cart);
    return updatedCart;
}

const addToCart = (cart: Cart, item: Item) => {
    if (cart.items.has(item)) {
        const currentQuantity = cart.items.get(item) as number;
        cart.items.set(item, currentQuantity + 1);
    }
    else {
        cart.items.set(item, 1);
    }
}

const removeFromCart = (cart: Cart, item: Item) => {
    if (cart.items.has(item)) {
        const currentQuantity = cart.items.get(item) as number;
        const updateQuantity = currentQuantity - 1;

        if (updateQuantity <= 0)
            cart.items.delete(item);
        else
            cart.items.set(item, updateQuantity);
    }
}

const calculateTotalCartAmount = (cart: Cart) => {
    cart.totalAmount = 0;

    if (cart.items) {
        cart.items.forEach((quantity: number, item: Item) => {
            cart.totalAmount = cart.totalAmount + (quantity * item.price);
        });
    }
}

export default CartReducer;